<template>
    <section>
        <modal ref="modalEditarCliente" :titulo="`Actualizar datos del ${$config.cliente}`" @guardar="editar_cliente">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center">
                    <div class="col-12 text-center mb-4">
                        Seleccione un Avatar
                    </div>
                    <div class="avatar-box rounded-circle cr-pointer">
                        <img :src="model.avatar" width="100%" height="100%" alt="" @click="seleccionarAvatar()" />
                    </div>
                </div>
                <div class="row mx-0 mt-3">
                    <div class="col-12 my-2">
                        <ValidationProvider v-slot="{errors}" rules="required|max:17" name="nombres">
                            <label class="f-15 pl-3"> Nombres </label>
                            <el-input v-model="model.prop_nombre" class="w-100" maxlength="17" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-2">
                        <ValidationProvider v-slot="{errors}" rules="required|max:18" name="apellidos">
                            <label class="f-15 pl-3"> Apellidos </label>
                            <el-input v-model="model.prop_apellido" class="w-100" maxlength="18" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-2">
                        <ValidationProvider v-slot="{errors}" rules="required" vid="password" name="contraseña">
                            <label class="f-15 pl-3">Contraseña </label>
                            <el-input v-model="model.password" class="w-100" show-password />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-2">
                        <ValidationProvider v-slot="{ errors }" rules="required_if:password|confirmed:password" name="confirmar contraseña">
                            <label class="f-15 pl-3">Confirmar contraseña </label>
                            <el-input v-model="model.password_confirmation" class="w-100" show-password />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-2">
                        <label class="f-15 pl-3"> ¿Cuándo naciste? </label>
                        <el-date-picker
                        v-model="model.nacimiento"
                        type="date"
                        size="small"
                        value-format="yyyy-MM-dd"
                        format="dd MMM yyyy"
                        :clearable="false"
                        />
                    </div>
                    <div class="col-12 text-center mt-3">
                        Selecciona el genero con el que te identificas
                    </div>
                    <div class="col-12 d-middle-center my-3">
                        <el-radio-group v-model="model.genero">
                            <el-radio class="radio-red" :label="3">
                                Femenino
                            </el-radio>
                            <el-radio class="radio-red" :label="2">
                                Masculino
                            </el-radio>
                            <el-radio class="radio-red" :label="1">
                                Otro
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <!-- <div class="col-12 my-2">
                        ¿Donde Vives?
                    </div>
                    <div class="col-12 my-2">
                        <ValidationProvider v-slot="{errors}" rules="required" name="país">
                            <p class="ml-2">País</p>
                            <el-select v-model="model.idm_pais" class="w-100" :disabled="disabled" filterable @change="consultarEstados">
                                <el-option
                                v-for="item in paises"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-2">
                        <ValidationProvider v-slot="{errors}" rules="required" name="estado">
                            <p class="ml-2">Estado</p>
                            <el-select v-model="model.idm_estado" class="w-100" :disabled="disabled" filterable @change="consultarCiudades">
                                <el-option
                                v-for="item in estados"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-2">
                        <ValidationProvider v-slot="{errors}" rules="required" name="ciudad">
                            <p class="ml-2">Ciudad</p>
                            <div class="row mx-0">
                                <el-select v-model="model.idm_ciudad" class="col" :disabled="disabled" filterable>
                                    <el-option
                                    v-for="item in ciudades"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                                <i class="icon-pencil-outline f-22 cr-pointer" @click="disabled = !disabled" />
                            </div>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-2">
                        <ValidationProvider v-slot="{errors}" rules="required|max:70" name="dirección">
                            <label class="f-15 pl-3"> Dirección </label>
                            <el-input v-model="model.direccion" class="w-100 br-12" maxlength="70" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-2">
                        <img :src="rutaImagenMapa()" class="w-100 cr-pointer" @click="abrirModalMapa" />
                    </div>
                    <div class="col-12 my-2">
                        <ValidationProvider v-slot="{errors}" rules="max:40" name="anexo">
                            <label class="f-15 pl-3"> Anexo </label>
                            <el-input v-model="model.anexo" class="w-100 br-12" placeholder="Apto, casa, ofc..." maxlength="40" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 my-2">
                        <ValidationProvider v-slot="{errors}" rules="required|max:25" name="guardar como">
                            <label class="f-15 pl-3"> Guardar como </label>
                            <el-input v-model="model.guardar_como" class="w-100 br-12" placeholder="Apto, casa, ofc..." maxlength="25" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div> -->
                </div>
            </ValidationObserver>
        </modal>
        <modal-seleccionar-avatar ref="SelectAvatar" @avatar="elegir_avatar" />
        <mapa-google-modal
        ref="modalMapaGoogle"
        :coordenadas-entrada="coordenadas"
        @actualizar="actualizarCoordenadasCiudad"
        />
    </section>
</template>
<script>
import Clientes from "../../../services/clientes";
import Localizaciones from "../../../services/proyectos/localizaciones";
import { mapGetters } from 'vuex'

export default {
    components: {
        modalSeleccionarAvatar: () => import('../partials/modalSeleccionarAvatar')
    },
    data(){
        return{
            disabled: true,
            paises: [],
            estados: [],
            ciudades: [],
            coordenadas: {
                lat: 0,
                lng: 0,
            },
            model:{
                prop_nombre: null,
                prop_apellido: null,
                genero: null,
                nacimiento: null,
                avatar: null,
                id_avatar: null,
                password: null,
                password_confirmation: null,
            },
            id: 0
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    // watch: {
    //     'model.idm_pais'(pais){
    //         this.consultarEstados()
    //     },
    //     'model.idm_estado'(estado){
    //         this.consultarCiudades()
    //     },
    // },
    methods: {
        async toggle({id}){
            this.id = id
            await this.show()
            // this.model = {...params}
            this.$refs.modalEditarCliente.toggle();
        },
        async show(){
            try {
                const { data } = await Clientes.show(this.id)
                this.model.prop_nombre = data.prop_nombre
                this.model.prop_apellido = data.prop_apellido
                this.model.genero = data.genero
                this.model.nacimiento = data.nacimiento
                this.model.avatar = data.avatar
                this.model.id_avatar = data.id_avatar
                this.model.id = this.id
            } catch (error){
                this.error_catch(error)
            }
        },
        seleccionarAvatar(){
            this.$refs.SelectAvatar.toggle(this.model.id_avatar)
        },
        async editar_cliente(){
            try {

                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }

                const {data} = await Clientes.editar_cliente(this.model)
                this.$validar(data)
                this.notificacion('Mensaje', 'Editado exitosamente', 'success')
                this.$emit('listar')
                this.limpiar()
                this.$refs.modalEditarCliente.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model = {
                prop_nombre: null,
                prop_apellido: null,
                genero: null,
                nacimiento: null,
                avatar: null,
                id_avatar: null,
                password_confirmation: null,
                password: null,
            }
            this.id = 0
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        async consultarPaises(){
            try {
                const {data} = await Localizaciones.consultarPaises(this.$usuario.proyecto.id)
                this.paises = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarEstados(){
            try {
                this.model.idm_estado = null
                this.model.idm_ciudad = null
                this.estados = []
                this.ciudades = []
                const {data} = await Localizaciones.consultarEstados({
                    proyecto: this.$usuario.proyecto.id,
                    pais: this.model.idm_pais
                })

                this.estados = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarCiudades(){
            try {
                this.model.idm_ciudad = null
                this.ciudades = []
                const {data} = await Localizaciones.consultarCiudades({
                    proyecto: this.$usuario.proyecto.id,
                    estado: this.model.idm_estado
                })
                this.model.idm_ciudad = null
                this.ciudades = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        actualizarCoordenadasCiudad({ latitud, longitud }){
            console.log(latitud,longitud);
            this.model.latitud = latitud
            this.model.longitud = longitud
            this.coordenadas.lat = +this.model.latitud
            this.coordenadas.lng = +this.model.longitud
        },
        elegir_avatar(avatar){
            this.model.avatar = avatar.imagen_firmada
            this.model.id_avatar = avatar.id
            console.log('avatar',avatar);
        }
    }
}
</script>
